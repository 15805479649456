






import {Vue, Component, Prop} from 'vue-property-decorator';

@Component
export default class ProductsListFilters extends Vue {

}
